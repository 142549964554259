<template>
  <div class="col">
    <section class="card">
      <div class="twt-feed blue-bg">
        <div class="corner-ribon black-ribon">
          <i class="fa fa-asterisk"></i>
        </div>
        <div class="fa fa-cog wtt-mark"></div>
        <div class="media">
          <div class="media-body">
            <h2 class="text-white display-6">Configuraciones</h2>
            <p class="text-light"></p>
          </div>
        </div>
      </div>
      <div class="weather-category twt-category">
        <select name="" id="" v-model="instance.ciclo">
          <option >--ciclo--</option>
          <option
          v-for="(item, index) in ciclos" :value="item.id" :key="index">{{item.name}}</option>
        </select>
      </div>
      <footer class="twt-footer">
        <a href="#"><i class="fa fa-map-marker"></i></a>
        Settings
        <span class="pull-right" title="Edad">
          Settings
        </span>
      </footer>
   </section>
  </div>
</template>
<script>

/* eslint-disable */
export default {
  created () {
    this.get_ciclos()
    this.get_settings()
  },
  name: 'Settings',
  data() {
    return {
      instance: [],
      ciclos: [],
    };
  },
  methods: {
    get_settings () {
      const payload = {};
      const self = this;
      this.sendRequest(payload, 'settings/').then((data) => {
        self.instance = data.data;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener datos.', 'error');
      });
    },
    get_ciclos () {
      const payload = {};
      const self = this;
      this.sendRequest(payload, 'ciclos/').then((data) => {
        self.ciclos = data.data.results;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener datos.', 'error');
      });
    },
    save_settings() {
      self.save_storage('settins', true);
    },
    default_settings() {      
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
  },
};

</script>
